import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import DashboardLayout from "../components/DashboardLayout";
import Swal from "sweetalert2";
import Api from '../api/Api';

const CarOwnerUpdate = () => {
  const navigate = useNavigate();
  const {getRequest, patchRequest} = Api();
  const { owner_id } = useParams()

  const [loading, setLoading] = useState(false);
  const [firstname, setFirstName] = useState("");
   const [lastname, setLastName] = useState("");
   const [mobilenumber, setMobileNumber] = useState("");
   const [address, setAddress] = useState("");
   const [email, setEmail] = useState("");

   const [carowner, setCarOwner] = useState("");

   useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await getRequest('car_owner/' + owner_id);
            setCarOwner(response.data);
            setFirstName(response.data.first_name);
            setLastName(response.data.last_name);
            setMobileNumber(response.data.mobile);
            setAddress(response.data.address);
            setEmail(response.data.email);
        } catch (error) {
          console.error(error);
        }
      };
      fetchData();
   }, []);

  const handleUpdateCarOwner = async (e) => {
   e.preventDefault();
   setLoading(true);

   try { 
     const updateCarOwnerData = {
       owner_id: owner_id,
       first_name: firstname,
       last_name: lastname,
       mobile: mobilenumber,
       address: address,
       email: email,
     };
 
     const response2 = await patchRequest('car_owner/' + owner_id, updateCarOwnerData);
     if (response2.status === 201) {
       showNotification("success", 'Data Updated successfully!');
       setFirstName("");
       setLastName("");
       setMobileNumber("");
       setAddress("");
       setEmail("");
       navigate('/carowners');
     }
   } catch (error) {
     if (firstname === '' || lastname === '' || mobilenumber === '' || address === '' || email === '') {
       showNotification("error", "Please enter all the fields");
     } else {
       if (error.response && error.response.status === 422) {
         const errorDetail = error.response.data.detail;
         errorDetail.forEach(error => {
           const message = error.msg;
           showNotification("error", message);
         });
       } else if (error.response && error.response.status === 400) {
         showNotification("error", error.response.data.detail);
       } else {
         showNotification("error", "Server Error");
       }
     }
   } finally {
     setLoading(false);
   }
 };

const showNotification = (icon, title) => {
  Swal.fire({
    icon: icon,
    title: title,
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 4000,
    timerProgressBar: true,
    customClass: {
      popup: "colored-toast",
    },
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
};
 
  return (
   <DashboardLayout>
    <div className="content-body">
         <div className="container-fluid">
         <div className="row">
                  <div className="col-md-8 mx-auto">
                     <div className="page-heading">
                        <div className="page-heading__title">
                           <h3>
                           {/* <a href="#" onClick={goBack} className="btn btn-sm btn-info mr-2">
                              {' '}
                              <i className="fa fas fa-reply" /> Back
                           </a> */}
                           Update Car Owner - {carowner.first_name} {carowner.last_name}
                           </h3>
                        </div>
                        
                     </div>
                  </div>
               </div>


      <div className="row mb-3">
      <div className="col-md-8 mx-auto">
      <div className="card">
                              <div className="card-body">
                              <p className="mb-4">Update Car Owner Information</p>
                              <form onSubmit={handleUpdateCarOwner}>
                              <div className="form-row">
                              <div className="col-md-6">
                              <div className="form-group">
                                 <label htmlFor="firstname">Enter First Name</label>
                                 <input
                                    type="text"
                                    name="firstname"
                                    className="form-control"
                                    value={firstname}
                                    onChange={(e) => setFirstName(e.target.value)}
                                 />
                              </div>
                              </div> 
                              <div className="col-md-6">
                              <div className="form-group">
                                 <label htmlFor="lastname">Enter Last Name</label>
                                 <input
                                    type="text"
                                    name="lastname"
                                    className="form-control"
                                    value={lastname}
                                    onChange={(e) => setLastName(e.target.value)}
                                 />
                              </div>
                              </div>
                              
                              </div>

                              <div className="form-row">
                              <div className="col-md-6">
                              <div className="form-group">
                                 <label htmlFor="mobilenumber">Enter Mobile Number</label>
                                 <input
                                    type="text"
                                    name="mobilenumber"
                                    className="form-control"
                                    value={mobilenumber}
                                    onChange={(e) => setMobileNumber(e.target.value)}
                                 />
                              </div>
                              </div>

                              <div className="col-md-6">
                              <div className="form-group">
                                 <label htmlFor="address">Enter Address</label>
                                 <input
                                    type="text"
                                    name="address"
                                    className="form-control"
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                 />
                              </div>
                              </div>
                              </div>
                              <div className="form-row">
                              <div className="col-md-6">
                              <div className="form-group">
                                 <label htmlFor="email">Enter Email</label>
                                 <input
                                    type="email"
                                    name="email"
                                    className="form-control"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                 />
                              </div>
                              </div>
                              </div>
                              <div className="mt-4">
                                 <button
                                    type="submit"
                                    className="btn btn-info btn-block"
                                    onClick={handleUpdateCarOwner}
                                    disabled={loading}
                                 >
                                    {loading ? (
                                    <>
                                       <span
                                          className="spinner-border spinner-border-sm"
                                          role="status"
                                          aria-hidden="true"
                                       ></span>
                                       <span className="sr-only">Loading...</span>
                                    </>
                                    ) : (
                                    "Update Data"
                                    )}
                                 </button>
                                 </div>                      
                              </form>
                              </div>
                              </div>
        </div>
      </div>
    </div>
    </div>
</DashboardLayout>

  );
};

export default CarOwnerUpdate;