import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import DashboardLayout from "../components/DashboardLayout";
import Swal from "sweetalert2";
import Api from '../api/Api';

const CarUpdate = () => {
  const navigate = useNavigate();
  const {getRequest, patchRequest} = Api();
  const { reg_no } = useParams()

  const [loading, setLoading] = useState(false);
  const [regnumber, setRegNumber] = useState("");
  const [carmake, setCarMake] = useState("");
  const [carmodel, setCarModel] = useState("");
  const [carowners, setCarOwner] = useState([]);
  const [selectedCarOwner, setSelectedCarOwner] = useState('');
  const [car, setCar] = useState("");

   useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await getRequest('car/' + reg_no);
            setCar(response.data);
            setRegNumber(response.data.reg_number);
            setCarMake(response.data.car_make);
            setCarModel(response.data.car_model);
            setSelectedCarOwner(response.data.user_id);
        } catch (error) {
          console.error(error);
        }
      };
      const fetchCarOwners = async () => {
         try {
             const response = await getRequest('car_owner');
             setCarOwner(response.data);
           } catch (error) {
             console.error(error);
         }
       };
       fetchCarOwners();
      fetchData();
   }, []);

  const handleUpdateCar = async (e) => {
   e.preventDefault();
   setLoading(true);

   try { 
     const updateCarOwner = {
      reg_number: regnumber,
      car_make: carmake,
      car_model: carmodel,
      user_id: selectedCarOwner
     };
 
     const response2 = await patchRequest('car/' + reg_no, updateCarOwner);
     if (response2.status === 201) {
       showNotification("success", 'Data Updated successfully!');
       navigate('/cars');
     }
   } catch (error) {
      if(regnumber == '' || carmake == '' || carmodel == '' || selectedCarOwner == ''){
       showNotification("error", "Please enter all the fields");
     } else {
       if (error.response && error.response.status === 422) {
         const errorDetail = error.response.data.detail;
         errorDetail.forEach(error => {
           const message = error.msg;
           showNotification("error", message);
         });
       } else if (error.response && error.response.status === 400) {
         showNotification("error", error.response.data.detail);
       } else {
         showNotification("error", "Server Error");
       }
     }
   } finally {
     setLoading(false);
   }
 };

const showNotification = (icon, title) => {
  Swal.fire({
    icon: icon,
    title: title,
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 4000,
    timerProgressBar: true,
    customClass: {
      popup: "colored-toast",
    },
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
};
 
  return (
   <DashboardLayout>
    <div className="content-body">
         <div className="container-fluid">
         <div className="row">
                  <div className="col-md-8 mx-auto">
                     <div className="page-heading">
                        <div className="page-heading__title">
                           <h3>
                           {/* <a href="#" onClick={goBack} className="btn btn-sm btn-info mr-2">
                              <i className="fa fas fa-reply" /> Back
                           </a> */}
                           Update Car - {car.reg_number}
                           </h3>
                        </div>
                        
                     </div>
                  </div>
               </div>


      <div className="row mb-3">
      <div className="col-md-8 mx-auto">
      <div className="card">
                              <div className="card-body">
                              <p className="mb-4">Update Car Information</p>
                              <form onSubmit={handleUpdateCar}>
                              <div className="form-row">
                              <div className="col-md-6">
                              <div className="form-group">
                                 <label htmlFor="regnumber">Enter Reg Number</label>
                                 <input
                                    type="text"
                                    name="regnumber"
                                    className="form-control"
                                    value={regnumber}
                                    onChange={(e) => setRegNumber(e.target.value)}
                                 />
                              </div>
                              </div> 
                              <div className="col-md-6">
                              <div className="form-group">
                                 <label htmlFor="carmake">Enter Car Make</label>
                                 <input
                                    type="text"
                                    name="carmake"
                                    className="form-control"
                                    value={carmake}
                                    onChange={(e) => setCarMake(e.target.value)}
                                 />
                              </div>
                              </div>
                              
                              </div>

                              <div className="form-row">
                              <div className="col-md-6">
                              <div className="form-group">
                                 <label htmlFor="carmodel">Enter Car Model</label>
                                 <input
                                    type="text"
                                    name="carmodel"
                                    className="form-control"
                                    value={carmodel}
                                    onChange={(e) => setCarModel(e.target.value)}
                                 />
                              </div>
                              </div>
                              <div className="col-md-6">
    <div className="form-group">
        <label htmlFor="carownerid">Car Owner</label>
        <select
            className="form-control"
            name="carownerid"
            id="carownerid"
            value={selectedCarOwner}
            onChange={(e) => setSelectedCarOwner(e.target.value)}
        >
            {carowners.map(owner => (
                <option
                    key={owner.owner_id}
                    value={owner.owner_id}
                    selected={owner.owner_id === selectedCarOwner}
                >
                    {owner.first_name} {owner.last_name}
                </option>
            ))}
        </select>
        <span className="invalid-feedback" />
    </div>
</div>


                              </div>
                              
                              <div className="mt-4">
                                 <button
                                    type="submit"
                                    className="btn btn-info btn-block"
                                    onClick={handleUpdateCar}
                                    disabled={loading}
                                 >
                                    {loading ? (
                                    <>
                                       <span
                                          className="spinner-border spinner-border-sm"
                                          role="status"
                                          aria-hidden="true"
                                       ></span>
                                       <span className="sr-only">Loading...</span>
                                    </>
                                    ) : (
                                    "Update Data"
                                    )}
                                 </button>
                                 </div>                      
                              </form>
                              </div>
                              </div>
        </div>
      </div>
    </div>
    </div>
</DashboardLayout>

  );
};

export default CarUpdate;