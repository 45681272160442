import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import DashboardLayout from "../components/DashboardLayout";
import Swal from "sweetalert2";
import Api from '../api/Api';

const CardUpdate = () => {
  const navigate = useNavigate();
  const {getRequest, patchRequest} = Api();
  const { card_id } = useParams()

  const [loading, setLoading] = useState(false);
  const [card, setCard] = useState("");
  const [cardid, setCardId] = useState("");
  const [carbalance, setCarBalance] = useState("");

   useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await getRequest('card/' + card_id);
            setCard(response.data);
            setCardId(response.data.card_id);
            setCarBalance(response.data.car_balance);
        } catch (error) {
          console.error(error);
        }
      };
      fetchData();
   }, []);

  const handleUpdateCard = async (e) => {
   e.preventDefault();
   setLoading(true);

   try { 
     const updateCard = {
      card_id: cardid,
      is_active: 'true',
      car_balance: carbalance,
      user_id: 21
     };
 
     const response2 = await patchRequest('car/' + card_id, updateCard);
     if (response2.status === 201) {
       showNotification("success", 'Data Updated successfully!');
       navigate('/cars');
     }
   } catch (error) {
      if(cardid == '' || carbalance == ''){
       showNotification("error", "Please enter all the fields");
     } else {
       if (error.response && error.response.status === 422) {
         const errorDetail = error.response.data.detail;
         errorDetail.forEach(error => {
           const message = error.msg;
           showNotification("error", message);
         });
       } else if (error.response && error.response.status === 400) {
         showNotification("error", error.response.data.detail);
       } else {
         showNotification("error", "Server Error");
       }
     }
   } finally {
     setLoading(false);
   }
 };

const showNotification = (icon, title) => {
  Swal.fire({
    icon: icon,
    title: title,
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 4000,
    timerProgressBar: true,
    customClass: {
      popup: "colored-toast",
    },
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
};
 
  return (
   <DashboardLayout>
    <div className="content-body">
         <div className="container-fluid">
         <div className="row">
                  <div className="col-md-8 mx-auto">
                     <div className="page-heading">
                        <div className="page-heading__title">
                           <h3>
                           {/* <a href="#" onClick={goBack} className="btn btn-sm btn-info mr-2">
                              <i className="fa fas fa-reply" /> Back
                           </a> */}
                           Update Card - {card.card_id}
                           </h3>
                        </div>
                        
                     </div>
                  </div>
               </div>


      <div className="row mb-3">
      <div className="col-md-8 mx-auto">
      <div className="card">
                              <div className="card-body">
                              <p className="mb-4">Update Card Information</p>
                              <form onSubmit={handleUpdateCard}>
                              <div className="form-row">
                              <div className="col-md-6">
                              <div className="form-group">
                                 <label htmlFor="cardid">Enter Card ID</label>
                                 <input
                                    type="text"
                                    name="cardid"
                                    className="form-control"
                                    value={cardid}
                                    onChange={(e) => setCardId(e.target.value)}
                                 />
                              </div>
                              </div> 
                              <div className="col-md-6">
                              <div className="form-group">
                                 <label htmlFor="carbalance">Enter Car Balance</label>
                                 <input
                                    type="number"
                                    name="carbalance"
                                    className="form-control"
                                    value={carbalance}
                                    onChange={(e) => setCarBalance(e.target.value)}
                                 />
                              </div>
                              </div>
                              
                              </div>
                              
                              <div className="mt-4">
                                 <button
                                    type="submit"
                                    className="btn btn-info btn-block"
                                    onClick={handleUpdateCard}
                                    disabled={loading}
                                 >
                                    {loading ? (
                                    <>
                                       <span
                                          className="spinner-border spinner-border-sm"
                                          role="status"
                                          aria-hidden="true"
                                       ></span>
                                       <span className="sr-only">Loading...</span>
                                    </>
                                    ) : (
                                    "Update Data"
                                    )}
                                 </button>
                                 </div>                      
                              </form>
                              </div>
                              </div>
        </div>
      </div>
    </div>
    </div>
</DashboardLayout>

  );
};

export default CardUpdate;