import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import SignIn from "./pages/SignIn";

import Users from "./pages/Users";
import NewUser from "./pages/NewUser";
import Cars from "./pages/Cars";
import CarUpdate from "./pages/CarUpdate";
import CarOwners from "./pages/CarOwners";
import CarOwnerUpdate from "./pages/CarOwnerUpdate";
import Terminals from "./pages/Terminals";
import TerminalUpdate from "./pages/TerminalUpdate";
import Cards from "./pages/Cards";
import CardUpdate from "./pages/CardUpdate";
import CardOwners from "./pages/CardOwners";
import CardOwnerUpdate from "./pages/CardOwnerUpdate";

import Account from "./pages/Account";
import Contract from "./pages/Contract";
import Profile from "./pages/Profile";
import Dashboard from "./pages/Dashboard";
import Notifications from "./pages/Notifications";
import HttpApi from './api/HttpApi';

function App() {
  const { getToken } = HttpApi();
  const token = getToken();
  const isAuthenticated = !!token;


  return (
    <BrowserRouter>
      <Routes>
        <Route exact path='/' element={<SignIn />} />
        <Route exact path='/signin' element={<SignIn />} />

        <Route exact path='/users' element={<Users />} />
        <Route exact path='/newuser' element={<NewUser />} />
        <Route exact path='/cars' element={<Cars />} />
        <Route exact path='/car/update/:reg_no' element={<CarUpdate />} />
        <Route exact path='/carowners' element={<CarOwners />} />
        <Route exact path='/carowner/update/:owner_id' element={<CarOwnerUpdate />} />
        <Route exact path='/terminals' element={<Terminals />} />
        <Route exact path='/terminal/update/:terminal_id' element={<TerminalUpdate />} />
        <Route exact path='/cards' element={<Cards />} />
        <Route exact path='/card/update/:card_id' element={<CardUpdate />} />
        <Route exact path='/cardowners' element={<CardOwners />} />
        <Route exact path='/cardowner/update/:user_id' element={<CardOwnerUpdate />} />
        
        <Route exact path='/dashboard' element={<Dashboard />} />
        <Route exact path='/account' element={<Account />} />
        <Route exact path='/contracts' element={<Contract />} />
        <Route exact path='/profile' element={<Profile />} />
        <Route exact path='/notifications' element={<Notifications />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
