import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Swal from "sweetalert2";
import Api from '../api/Api';


const SignIn = () => {

  const navigate = useNavigate();
  const {postToken, getToken, setToken} = Api();

  const [username , setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const handleFocus = (field) => {
    setErrors((prevErrors) => ({ ...prevErrors, [field]: undefined }));
  };

  const handleSignIn = async (e) => {
    e.preventDefault();
    setLoading(true);

    const logindata = {
      grant_type: "",
      username: username,
      password: password,
      scope: "",
      client_id: "",
      client_secret: ""
    };
    
    try {
      const response = await postToken("token", logindata);

      if (response.status === 200) {
        setToken(response.data.access_token);
        getToken(response.data.access_token);
       // console.log(response.data.access_token);
        setErrors({});
        showNotification("success", "Logged In Successfully");
       navigate("/dashboard");
      } else if (response.status === 422) {
        setErrors(response.error);
        showNotification("error", response.data.detail.type);
      }
    } catch (error) {
      console.log(error);
      //showNotification("error", "Sign-in failed. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  

const showNotification = (icon, title) => {
  Swal.fire({
    icon: icon,
    title: title,
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 4000,
    timerProgressBar: true,
    customClass: {
      popup: "colored-toast",
    },
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
};
 
  return (
    <main className="main-wrapper">
      <div className="col-md-4 mx-auto">
          <div className="card mt-60 auth-form">
            <div className="card-body">
              <div className="p-4">

              <div className="text-center mb-3">
              <img src="/images/wlogo.png" alt=""/>
                </div>
              <p className="mb-4">Enter signin credentials to access Dashboard.</p>
                
              <form onSubmit={handleSignIn}>
              <div className="form-group">
        <label htmlFor="username">Enter Username/Mobile Number</label>
        <input
          type="text"
          name="username"
          className={`form-control ${
            errors.username ? "is-invalid" : ""
          }`}
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          onFocus={() => handleFocus('username')}
        />
        {errors.username && (
          <div className="invalid-feedback">{errors.username[0]}</div>
        )}
      </div>
      <div className="form-group">
        <label htmlFor="password">Enter Password</label>
        <input
          type="password"
          name="password"
          className={`form-control ${
            errors.password ? "is-invalid" : ""
          }`}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onFocus={() => handleFocus('password')}
        />
        {errors.password && (
          <div className="invalid-feedback">{errors.password[0]}</div>
        )}
      </div>
                <div className="mt-4">
                <button
        type="submit"
        className="btn btn-info btn-lg btn-block"
        onClick={handleSignIn}
        disabled={loading}
      >
        {loading ? (
          <>
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Loading...</span>
          </>
        ) : (
          "Sign In"
        )}
      </button>
                </div>                      
              </form>
            </div>
              </div>
            </div>
        </div>

      
</main>

  );
};

export default SignIn;