//import axios from 'axios';
import axios from '../lib/axios';
import { useState, useEffect } from 'react';
import { Link, Route, Routes} from "react-router-dom";

const baseURL = 'https://endobo.io/'; //'http://137.43.130.42:34567/';

export default function HttpApi() {
   
   const csrfTokenCookie = decodeURIComponent(document.cookie.split('; ').find(row => row.startsWith('XSRF-TOKEN')));
   const csrfToken = csrfTokenCookie.split('=')[1];
   

   const getToken = () =>{
      const tokenString = localStorage.getItem('token');
      const userToken = JSON.parse(tokenString);
      return userToken;
  }

  const getUser = () =>{
    const userString = localStorage.getItem('user');
    const user_detail = JSON.parse(userString);
    return user_detail;
  }

  const [token,setToken] = useState(getToken());
  const [user,setUser] = useState(getUser());

    const saveToken = (user,token) =>{
      localStorage.setItem('token',JSON.stringify(token));
      localStorage.setItem('user',JSON.stringify(user));
      setToken(token);
      setUser(user);
      <Link to='/dashboard'></Link>
    }

    const logout = () => {
        sessionStorage.clear();
        <Link to='/'></Link>
    }

    const http = axios.create({
        baseURL:baseURL,
        headers:{
            "Content-type" : "application/json",
           "Authorization" : `Bearer ${token}`,
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRF-TOKEN': csrfToken,
        }
    });

    const getRequest = (url) => {
        const headers = {
            Accept: 'application/json',
            "Content-type" : "application/json",
            "Authorization" : `Bearer ${token}`
           // 'X-CSRF-TOKEN': csrfToken,
        };
        const requestUrl = baseURL + url;
    
        return axios({
            method: 'GET',
            url: requestUrl,
            headers,
        });
    }
    
    const postRequest = (url, params) => {
        const headers = {
            Accept: 'application/json',
            "Content-type" : "application/json",
            "Authorization" : `Bearer ${token}`,
            'X-CSRF-TOKEN': csrfToken,
        };
        const requestUrl = baseURL + url;
    
        return axios({
            method: 'POST',
            url: requestUrl,
            data: params,
            headers,
        });
    }

    const postMediaRequest = (url, params) => {
        const headers = {
            Accept: 'application/json',
            "Content-type" : "application/json",
            //"Content-Type": "application/json;charset=UTF-8",
            "Authorization" : `Bearer ${token}`,
            'X-CSRF-TOKEN': csrfToken,
            "X-Requested-With" :"XMLHttpRequest",
            "Content-Type": "multipart/form-data"
        };
        const requestUrl = baseURL + url;
    
        return axios({
            method: 'POST',
            url: requestUrl,
            data: params,
            headers,
        });
    }


    return {
        setToken:saveToken,
        token,
        user,
        getToken,
        http,
        getRequest,
        postRequest,
        postMediaRequest,
        logout
    };
}