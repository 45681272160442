
export default function Helper() {

  const userRole = (role) => { 
    let rrole = '';
    
    if (role === 1) {
      rrole = 'ADMINISTRATOR';
    } else if (role === 2) {
      rrole = 'CAR OWNER';
    } else if (role === 3) {
      rrole = 'CARD OWNER';
    } else {
      rrole = 'UNKNOWN ROLE';
    }

  return rrole;
  }

   const formatDate = (isoDate) => {
      const date = new Date(isoDate);
      const options = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true,
      };
      return date.toLocaleString('en-US', options);
    };

    const showDate = (isoDate) => {
      const date = new Date(isoDate);
      const options = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      };
    
      const formattedDate = date.toLocaleDateString('en-US', options);
      const [month, day, year] = formattedDate.split('/');
      const ddMmYyyyDate = `${day}-${month}-${year}`;
    
      return ddMmYyyyDate;
    };

    const timeAgo = (isoDate) => {
      const date = new Date(isoDate);
      const now = new Date();
    
      const diffInSeconds = Math.floor((now - date) / 1000);
    
      const intervals = {
        year: 31536000,
        month: 2592000,
        day: 86400,
        hour: 3600,
        minute: 60,
        second: 1,
      };
    
      let timeMessage = '';
    
      for (const [unit, secondsInUnit] of Object.entries(intervals)) {
        const interval = Math.floor(diffInSeconds / secondsInUnit);
    
        if (interval > 0) {
          timeMessage = `${interval} ${unit}${interval > 1 ? 's' : ''} ago`;
          break;
        }
      }
    
      if (timeMessage === '') {
        timeMessage = 'Just now';
      }
    
      return timeMessage;
    };

    const showAmount = (amount, decimal = 2, separate = true) => {
      const currencySymbol = 'UGX ';
      let separator = '';
      if (separate) {
        separator = ',';
      }
      let printAmount = currencySymbol + Number(amount).toFixed(decimal).replace(/\B(?=(\d{3})+(?!\d))/g, separator);
      const exp = printAmount.split('.');
      if (exp[1] * 1 === 0) {
        printAmount = exp[0];
      }
    
      return printAmount;
    }


   return {
      userRole,
      formatDate,
      timeAgo,
      showAmount,
      showDate
  };
}