import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import DashboardLayout from "../components/DashboardLayout";
import Api from '../api/Api';
 
const Cards = () => {
   const navigate = useNavigate();
   const [activeTab, setActiveTab] = useState('cards');

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };
  const goBack = () => {
   // Implement your goBack function here
 };

   const { postRequest, getRequest} = Api();
   const [user, setUser] = useState([]);
   const [cards, setCard] = useState([]);
   
   useEffect(() => {
      const fetchCards = async () => {
         try {
             const response = await getRequest('card');
             setCard(response.data);
           } catch (error) {
             console.error(error);
         }
       };
       fetchCards();
   }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getRequest('user/me');
        if (response.status === 200) {
          setUser(response.data);
        } else if (response.status === 401) {
           console.log(response.data.detail);
        }else if (response.status === "error") {
           console.log(response.error);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
 }, []);

 const [loading, setLoading] = useState(false);
 const [cardid, setCardId] = useState("");
 const [carbalance, setCarBalance] = useState("");

 const handleNewCard = async (e) => {
   e.preventDefault();
   setLoading(true);

   const newCardData = {
      card_id: cardid,
      is_active: 'true',
      car_balance: carbalance,
      user_id: 21
   };
 
   try {
     const response = await postRequest("card/", newCardData);
     if (response.status === 201) {
       showNotification("success", 'New Car created successfully!');
       setCardId("");
       setCarBalance("");
       window.location.reload();
     }
   } catch (error) {
     if(cardid == '' || carbalance == ''){
       showNotification("error", "Please enter all the fields");
     }else {
       if (error.response && error.response.status === 422) {
         const errorDetail = error.response.data.detail;
         errorDetail.forEach(error => {
           const message = error.msg;
           showNotification("error", message);
         });
       } else if (error.response && error.response.status === 400) {
         showNotification("error", error.response.data.detail);
       } else {
         showNotification("error", "Server Error");
       }
     }
   } finally {
     setLoading(false);
   }
 };

const showNotification = (icon, title) => {
 Swal.fire({
   icon: icon,
   title: title,
   toast: true,
   position: "top-end",
   showConfirmButton: false,
   timer: 4000,
   timerProgressBar: true,
   customClass: {
     popup: "colored-toast",
   },
   didOpen: (toast) => {
     toast.addEventListener("mouseenter", Swal.stopTimer);
     toast.addEventListener("mouseleave", Swal.resumeTimer);
   },
 });
};


   return ( 
      <DashboardLayout>
        <div className="content-body">
            <div className="container-fluid">
               <div className="row">
                  <div className="col-md-8 mx-auto">
                     <div className="page-heading">
                        <div className="page-heading__title">
                           <h3>
                           {/* <a href="#" onClick={goBack} className="btn btn-sm btn-info mr-2">
                              {' '}
                              <i className="fa fas fa-reply" /> Back
                           </a> */}
                           Cards
                           </h3>
                        </div>
                        <div className="page-heading__title">
                           <ul className="nav nav-tabs">
                           <li className="nav-item">
                              <a
                                 className={`nav-link ${activeTab === 'cards' ? 'active' : ''}`}
                                 href="#cards"
                                 onClick={() => handleTabClick('cards')}
                              >
                                 <i className="fas fa-chart-line" /> Cards
                              </a>
                           </li>
                           <li className="nav-item">
                              <a
                                 className={`nav-link ${activeTab === 'newcard' ? 'active' : ''}`}
                                 href="#newcard"
                                 onClick={() => handleTabClick('newcard')}
                              >
                                 <i className="far fa-user" /> New Card
                              </a>
                           </li>
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
         
               <div className="row mb-3">
                  <div className="col-md-8 mx-auto">
                     <div className="page-title-content">
                        <div className="tab-content">
                           <div className={`tab-pane show ${activeTab === 'cards' ? 'active' : ''}`} id="cards">
                           
                           <div className="card">
                              <div className="card-body">
                                 <h4 className="card-title">Registered Cards</h4>
                                 <div className="table-responsive">
                                    <table className="table mb-0">
                                    <thead>
                                       <tr>
                                          <th>#</th>
                                          <th>Card ID.</th>
                                          <th>Car Balance</th>
                                          <th>Status</th>
                                          <th>Action</th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                    {cards.map((card, index) => (
                                       <tr key={card.card_id}>
                                          <th scope="row">{index + 1}</th>
                                          <td>{card.card_id}</td>
                                          <td>{card.car_balance}</td>
                                          <td>{card.is_active}</td>
                                          <td>
                                          <Link to={`/card/update/${card.card_id}`}>
                                             <button className="btn btn-sm btn-dark mr-2"><i className="far fa-edit"/></button>
                                          </Link>
                                          <Link to="">
                                             <button className="btn btn-sm btn-danger"><i className="fa fa-trash"/></button>
                                          </Link>
                                          </td>
                                       </tr>
                                    ))}
                                    </tbody>
                                    </table>
                                 </div>
                              </div>
                           </div>
                           

                           </div>
                           <div className={`tab-pane ${activeTab === 'newcard' ? 'active' : ''}`} id="newcard">

                           <div className="row justify-content-md-center">
                              <div className="col-md-10">
                        <div className="card">
                              <div className="card-body">
                              <p className="mb-4">New Card Information</p>
                              <form onSubmit={handleNewCard}>
                              <div className="form-row">
                              <div className="col-md-6">
                              <div className="form-group">
                                 <label htmlFor="cardid">Enter Card ID</label>
                                 <input
                                    type="text"
                                    name="cardid"
                                    className="form-control"
                                    value={cardid}
                                    onChange={(e) => setCardId(e.target.value)}
                                 />
                              </div>
                              </div> 
                              <div className="col-md-6">
                              <div className="form-group">
                                 <label htmlFor="carbalance">Enter Car Balance</label>
                                 <input
                                    type="number"
                                    name="carbalance"
                                    className="form-control"
                                    value={carbalance}
                                    onChange={(e) => setCarBalance(e.target.value)}
                                 />
                              </div>
                              </div>
                              
                              </div>
                              
                              <div className="mt-4">
                                 <button
                                    type="submit"
                                    className="btn btn-info btn-block"
                                    onClick={handleNewCard}
                                    disabled={loading}
                                 >
                                    {loading ? (
                                    <>
                                       <span
                                          className="spinner-border spinner-border-sm"
                                          role="status"
                                          aria-hidden="true"
                                       ></span>
                                       <span className="sr-only">Loading...</span>
                                    </>
                                    ) : (
                                    "Save Data"
                                    )}
                                 </button>
                                 </div>                      
                              </form>
                              </div>
                              </div>
                        </div>
                        </div>
                           
                           

                           </div>
                        </div>
                     </div>
                  </div>
               </div>

            </div>
         </div>
      </DashboardLayout>
    );
};

export default Cards;