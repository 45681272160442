import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import DashboardLayout from "../components/DashboardLayout";
import Helper from '../functions/Helper';
import { useUserData } from "../hooks/global"; 
 
const Dashboard = () => {
  const user = useUserData();
  const { userRole} = Helper();

   return ( 
      <DashboardLayout>
        <div className="content-body">
          <div className="container-fluid">
           <div className="row mb-3">
              <div className="col-md-8 mx-auto">
                <div className="page-title-content">
                  <h4> User: { user.first_name} { user.last_name}</h4>
                  <h4> Role: {userRole(user.role)}</h4>
                </div>
              </div>
            </div> 
          </div>
        </div>
      </DashboardLayout>
    );
};

export default Dashboard;
