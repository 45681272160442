import axios from 'axios';
import { useState, useEffect } from 'react';
import { Link, Route, useNavigate } from "react-router-dom";

const baseURL = 'https://endobo.io/';

export default function Api() {
  const navigate = useNavigate();

const csrfTokenCookie = decodeURIComponent(document.cookie.split('; ').find(row => row.startsWith('XSRF-TOKEN')));
const csrfToken = csrfTokenCookie ? csrfTokenCookie.split('=')[1] : '';

const getToken = () => {
  const tokenString = localStorage.getItem('token');
  const userToken = JSON.parse(tokenString);
  return userToken;
};

const getUser = () => {
  const userString = localStorage.getItem('user');
  const user_detail = JSON.parse(userString);
  return user_detail;
};

const [token, setToken] = useState(getToken());
const [user, setUser] = useState(getUser());

const saveToken = (token) => {
  localStorage.setItem('token', JSON.stringify(token));
  //localStorage.setItem('user', JSON.stringify(user));
  setToken(token);
  //setUser(user);
  <Link to='/dashboard'></Link>
};

const logout = () => {
  localStorage.clear();
  navigate("/signin");
};

const http = axios.create({
  baseURL: baseURL,
  headers: {
   Accept: 'application/json',
    "Content-type": "application/json",
    "Authorization": `Bearer ${token}`,
   //'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-TOKEN': csrfToken,
  },
  withXSRFToken: true,
  withCredentials: true,
});

const getRequest = (url) => {
   const headers = {
      Accept: 'application/json',
      "Content-type": "application/json",
      "Authorization": `Bearer ${token}`,
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-TOKEN": csrfToken,
   };
  const requestUrl = baseURL + url;

  return axios({
    method: 'GET',
    url: requestUrl,
    headers,
    withCredentials: true,
  });
};

const postRequest = (url, params) => {
  const headers = {
    Accept: 'application/json',
    "Content-type": "application/json",
    "Authorization": `Bearer ${token}`,
    'X-CSRF-TOKEN': csrfToken,
  };
  const requestUrl = baseURL + url;

  return axios({
    method: 'POST',
    url: requestUrl,
    data: params,
    headers,
  });
};

const patchRequest = (url, params) => {
  const headers = {
    Accept: 'application/json',
    "Content-type": "application/json",
    "Authorization": `Bearer ${token}`,
    'X-CSRF-TOKEN': csrfToken,
  };
  const requestUrl = baseURL + url;

  return axios({
    method: 'PATCH',
    url: requestUrl,
    data: params,
    headers,
  });
};

const postToken = (url, params) => {
   const headers = {
     Accept: 'application/json',
     "Content-type": "application/x-www-form-urlencoded",
     'X-CSRF-TOKEN': csrfToken,
   };
   const requestUrl = baseURL + url;
 
   return axios({
     method: 'POST',
     url: requestUrl,
     data: params,
     headers,
   });
 };
 

const postMediaRequest = (url, params) => {
  const headers = {
    Accept: 'application/json',
    "Authorization": `Bearer ${token}`,
    'X-CSRF-TOKEN': csrfToken,
    "X-Requested-With": "XMLHttpRequest",
    "Content-Type": "multipart/form-data",
  };
  const requestUrl = baseURL + url;

  return axios({
    method: 'POST',
    url: requestUrl,
    data: params,
    headers,
  });
};

return {
setToken:saveToken,
  token,
  user,
  getToken,
  http,
  getRequest,
  postRequest,
  patchRequest,
  postMediaRequest,
  postToken,
  logout,
};

}
