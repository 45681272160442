import { createContext, useContext, useState, useEffect } from 'react';
import Api from '../api/Api';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const { getRequest, token } = Api();
  const [user, setUser] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const getUserData = async () => {
      try {
        const response = await getRequest('user/me');
        setUser(response.data);
      } catch (error) {
        console.error('Error fetching investor details:', error);
        
      }
    };
  
    if (token) {
      getUserData();
    } else {
      navigate('/');
    }
  }, [token, navigate]);
  

  return (
    <AuthContext.Provider value={{ token, user }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};