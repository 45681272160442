import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import DashboardLayout from "../components/DashboardLayout";
import Api from '../api/Api';
 
const CarOwners = () => {
   const navigate = useNavigate();
   const [activeTab, setActiveTab] = useState('carowners');

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };
  const goBack = () => {
   // Implement your goBack function here
 };

   const {postRequest, getRequest} = Api();

   const [loading, setLoading] = useState(false);
   const [firstname, setFirstName] = useState("");
   const [lastname, setLastName] = useState("");
   const [mobilenumber, setMobileNumber] = useState("");
   const [address, setAddress] = useState("");
   const [email, setEmail] = useState("");

   const [carowners, setCarOwner] = useState([]);

   useEffect(() => {
      const fetchCarOwners = async () => {
        try {
            const response = await getRequest('car_owner');
            setCarOwner(response.data);
          } catch (error) {
            console.error(error);
        }
      };
      fetchCarOwners();
   }, []);

const handleNewCarOwner = async (e) => {
  e.preventDefault();
  setLoading(true);

  const newData = {
    mobile_number: mobilenumber,
    role: 2,
    password: 'qwerty123',
  };

  try {
    const response1 = await postRequest("user/create/", newData);

    const newCarOwnerData = {
      owner_id: response1.data.user_id,
      first_name: firstname,
      last_name: lastname,
      mobile: mobilenumber,
      address: address,
      email: email,
    };

    const response2 = await postRequest("car_owner/", newCarOwnerData);
    if (response2.status === 201) {
      showNotification("success", 'New user created successfully!');
      setFirstName("");
      setLastName("");
      setMobileNumber("");
      setAddress("");
      setEmail("");
      window.location.reload();
    }
  } catch (error) {
    if (firstname === '' || lastname === '' || mobilenumber === '' || address === '' || email === '') {
      showNotification("error", "Please enter all the fields");
    } else {
      if (error.response && error.response.status === 422) {
        const errorDetail = error.response.data.detail;
        errorDetail.forEach(error => {
          const message = error.msg;
          showNotification("error", message);
        });
      } else if (error.response && error.response.status === 400) {
        showNotification("error", error.response.data.detail);
      } else {
        showNotification("error", "Server Error");
      }
    }
  } finally {
    setLoading(false);
  }
};

const showNotification = (icon, title) => {
 Swal.fire({
   icon: icon,
   title: title,
   toast: true,
   position: "top-end",
   showConfirmButton: false,
   timer: 4000,
   timerProgressBar: true,
   customClass: {
     popup: "colored-toast",
   },
   didOpen: (toast) => {
     toast.addEventListener("mouseenter", Swal.stopTimer);
     toast.addEventListener("mouseleave", Swal.resumeTimer);
   },
 });
};


   return ( 
      <DashboardLayout>
        <div className="content-body">
            <div className="container-fluid">
               <div className="row">
                  <div className="col-md-8 mx-auto">
                     <div className="page-heading">
                        <div className="page-heading__title">
                           <h3>
                           {/* <a href="#" onClick={goBack} className="btn btn-sm btn-info mr-2">
                              {' '}
                              <i className="fa fas fa-reply" /> Back
                           </a> */}
                           Car Owners
                           </h3>
                        </div>
                        <div className="page-heading__title">
                           <ul className="nav nav-tabs">
                           <li className="nav-item">
                              <a
                                 className={`nav-link ${activeTab === 'carowners' ? 'active' : ''}`}
                                 href="#carowners"
                                 onClick={() => handleTabClick('carowners')}
                              >
                                 <i className="fas fa-chart-line" /> Car Owners
                              </a>
                           </li>
                           <li className="nav-item">
                              <a
                                 className={`nav-link ${activeTab === 'newcarowner' ? 'active' : ''}`}
                                 href="#newcarowner"
                                 onClick={() => handleTabClick('newcarowner')}
                              >
                                 <i className="far fa-user" /> New Car Owner
                              </a>
                           </li>
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
         
               <div className="row mb-3">
                  <div className="col-md-8 mx-auto">
                     <div className="page-title-content">
                        <div className="tab-content">
                           <div className={`tab-pane show ${activeTab === 'carowners' ? 'active' : ''}`} id="carowners">
                           
                           <div className="card">
                              <div className="card-body">
                                 <h4 className="card-title">Registered Car Owners</h4>
                                 <div className="table-responsive">
                                    <table className="table mb-0">
                                    <thead>
                                       <tr>
                                          <th>#</th>
                                          <th>Names</th>
                                          <th>Mobile</th>
                                          <th>Address</th>
                                          <th>Email</th>
                                          <th>Action</th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                    {carowners.map((carowner, index) => (
                                       <tr key={carowner.owner_id}>
                                          <th scope="row">{index + 1}</th>
                                          <td>{carowner.first_name} {carowner.last_name}</td>
                                          <td>{carowner.mobile}</td>
                                          <td>{carowner.address}</td>
                                          <td>{carowner.email}</td>
                                          <td>
                                          <Link to={`/carowner/update/${carowner.owner_id}`}>
                                             <button className="btn btn-sm btn-dark mr-2"><i className="far fa-edit"/></button>
                                          </Link>
                                          <Link to="">
                                             <button className="btn btn-sm btn-danger"><i className="fa fa-trash"/></button>
                                          </Link>
                                          </td>
                                       </tr>
                                    ))}
                                    </tbody>
                                    </table>
                                 </div>
                              </div>
                           </div>
                           
                           </div>
                           <div className={`tab-pane ${activeTab === 'newcarowner' ? 'active' : ''}`} id="newcarowner">

                           <div className="row justify-content-md-center">
                              <div className="col-md-10">
                        <div className="card">
                              <div className="card-body">
                              <p className="mb-4">New Car Owner Information</p>
                              <form onSubmit={handleNewCarOwner}>
                              <div className="form-row">
                              <div className="col-md-6">
                              <div className="form-group">
                                 <label htmlFor="firstname">Enter First Name</label>
                                 <input
                                    type="text"
                                    name="firstname"
                                    className="form-control"
                                    value={firstname}
                                    onChange={(e) => setFirstName(e.target.value)}
                                 />
                              </div>
                              </div> 
                              <div className="col-md-6">
                              <div className="form-group">
                                 <label htmlFor="lastname">Enter Last Name</label>
                                 <input
                                    type="text"
                                    name="lastname"
                                    className="form-control"
                                    value={lastname}
                                    onChange={(e) => setLastName(e.target.value)}
                                 />
                              </div>
                              </div>
                              
                              </div>

                              <div className="form-row">
                              <div className="col-md-6">
                              <div className="form-group">
                                 <label htmlFor="mobilenumber">Enter Mobile Number</label>
                                 <input
                                    type="text"
                                    name="mobilenumber"
                                    className="form-control"
                                    value={mobilenumber}
                                    onChange={(e) => setMobileNumber(e.target.value)}
                                 />
                              </div>
                              </div>

                              <div className="col-md-6">
                              <div className="form-group">
                                 <label htmlFor="address">Enter Address</label>
                                 <input
                                    type="text"
                                    name="address"
                                    className="form-control"
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                 />
                              </div>
                              </div>
                              </div>
                              <div className="form-row">
                              <div className="col-md-6">
                              <div className="form-group">
                                 <label htmlFor="email">Enter Email</label>
                                 <input
                                    type="email"
                                    name="email"
                                    className="form-control"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                 />
                              </div>
                              </div>
                              </div>
                              <div className="mt-4">
                                 <button
                                    type="submit"
                                    className="btn btn-info btn-block"
                                    onClick={handleNewCarOwner}
                                    disabled={loading}
                                 >
                                    {loading ? (
                                    <>
                                       <span
                                          className="spinner-border spinner-border-sm"
                                          role="status"
                                          aria-hidden="true"
                                       ></span>
                                       <span className="sr-only">Loading...</span>
                                    </>
                                    ) : (
                                    "Save Data"
                                    )}
                                 </button>
                                 </div>                      
                              </form>
                              </div>
                              </div>
                        </div>
                        </div>
                           
                           

                           </div>
                        </div>
                     </div>
                  </div>
               </div>

            </div>
         </div>
      </DashboardLayout>
    );
};

export default CarOwners;