//import { Link } from "react-router-dom";
import { useNavigate, Link } from "react-router-dom";
import Swal from "sweetalert2";
import React, { useState, useEffect } from 'react';
import UseClickOutside from "../components/UseClickOutside";
import Helper from '../functions/Helper';
import Api from '../api/Api';
import { useUserData } from "../hooks/global"; 

const ToggleHeaderUser = () => {
  const user = useUserData();
  const { userRole} = Helper();
  const navigate = useNavigate();
  const { token, logout } = Api();

  const logOut = () => {
    if(token != undefined){
      logout();
      showNotification("success", 'Logged out successfully');
      navigate("/signin");
    }
}

const showNotification = (icon, title) => {
  Swal.fire({
    icon: icon,
    title: title,
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 4000,
    timerProgressBar: true,
    customClass: {
      popup: "colored-toast",
    },
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
};


  const [toggleuser, setToggleuser] = useState(false);
  let domNode = UseClickOutside(() => {
    setToggleuser(false);
  });

  return (
    <div className="profile_log dropdown" ref={domNode}>
      <div className="user" onClick={() => setToggleuser(!toggleuser)}>
        <span className="thumb">
          <img 
          src="/images/user1.png"
          className="rounded-circle"
          alt="photo" />
        </span>
        {/* <span>username</span> */}
        <span className="arrow">
          <i className="icofont-angle-down"></i>
        </span>
      </div>
      <div
        className={`dropdown-menu dropdown-menu-right ${
          toggleuser ? "show" : ""
        }`}
      >
        <div className="user-email">
          <div className="user">
            <span className="thumb">
              <img 
              src="/images/user1.png"
              className="rounded-circle"
              alt="photo" />
            </span>
            <div className="user-info">
              <h5>{user.last_name}</h5>
             <span>{userRole(user.role)}</span>
            </div>
          </div>
        </div>

        <Link to="/profile">
          <a className="dropdown-item">
            <i className="bi bi-person"></i>Profile
          </a>
        </Link>
        <Link to="/users">
          <a className="dropdown-item">
            <i className="bi bi-wallet"></i>Users
          </a>
        </Link>
        <a href="javascript:void(0)" className="dropdown-item logout" onClick={logOut}>
          <i className="bi bi-power"></i> Logout
        </a>
      </div>
    </div>
  );
};

export default ToggleHeaderUser;
