import React, { useState, useEffect } from 'react';
//import { useNavigate } from 'react-router-dom';
//import Helper from '../functions/Helper';
import Api from '../api/Api';

export function useUserData() {
  //const { userRole} = Helper();
  const { getRequest} = Api();
  const [user, setUser] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getRequest('user/me');
        if (response.status === 200) {
          if(response.data.role == 1) {
            setUser(response.data);
          }else if(response.data.role == 2) {
            const response1 = await getRequest('car_owner');
            setUser(response1.data);
          }else if(response.data.role == 3) {
            const response2 = await getRequest('card_owner');
            setUser(response2.data);
          }
        } else if (response.status === 401) {
           console.log(response.data.detail);
        }else if (response.status === "error") {
           console.log(response.error);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
 }, []);

  return user;
}