import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import UseClickOutside from "../components/UseClickOutside";

const NotificationToggle = () => {

//  const countNotifications = () => {
//   return notifications.length;
// };

  const [toggle, setToggle] = useState(false);
  let domNode = UseClickOutside(() => {
    setToggle(false);
  });
  return (
    <div className="notification dropdown" ref={domNode}>
      <div className="notification dropdown mr-3">
        {/* <a onclick="" href=""> */}
          <div className="notify-bell">
            <span className="noti-icon">
              <i className="bi bi-bell-fill text-white" />
              <span className="badge badge-danger badge-pill">0</span>
            </span>
          </div>
        {/* </a> */}
      </div>
    </div>
  );
};

export default NotificationToggle;
