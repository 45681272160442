import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Swal from "sweetalert2";
import Api from '../api/Api';

const NewUser = () => {
  const navigate = useNavigate();
  const {postRequest} = Api();

  const [loading, setLoading] = useState(false);
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [mobilenumber, setMobileNumber] = useState("");
  const [role, setRole] = useState(0);
  const [password, setPassword] = useState("");
  const [isactive, setActive] = useState('true');

  const handleNewUser = async (e) => {
    e.preventDefault();
    setLoading(true);

    const newUserData = {
      first_name: firstname,
      last_name: lastname,
      mobile_number: mobilenumber,
      role: role,
      is_active: isactive,
      password: password,
    };
  
    try {
      const response = await postRequest("user/create/", newUserData);
      if (response.status === 201) {
        showNotification("success", 'New user created successfully!');
        navigate("/signin");
      }
    } catch (error) {
      if(firstname == ''){
        showNotification("error", "Please enter all the fields");
      }else {
        if (error.response && error.response.status === 422) {
          const errorDetail = error.response.data.detail;
          errorDetail.forEach(error => {
            const message = error.msg;
            showNotification("error", message);
          });
        } else if (error.response && error.response.status === 400) {
          showNotification("error", error.response.data.detail);
        } else {
          showNotification("error", "Server Error");
        }
      }
    } finally {
      setLoading(false);
    }
  };

const showNotification = (icon, title) => {
  Swal.fire({
    icon: icon,
    title: title,
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 4000,
    timerProgressBar: true,
    customClass: {
      popup: "colored-toast",
    },
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
};
 
  return (
    <main className="main-wrapper">
  <section className="page-content">
    <div className="container">
      <div className="row justify-content-md-center mt-4">
        <div className="col-md-6">
        <div className="auth-form card">
              <div className="card-body">
              <p className="mb-4">New User Information</p>
              <form onSubmit={handleNewUser}>
              <div className="form-row">
              <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="firstname">Enter First Name</label>
                <input
                  type="text"
                  name="firstname"
                  className="form-control"
                  value={firstname}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
              </div> 
              <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="lastname">Enter Last Name</label>
                <input
                  type="text"
                  name="lastname"
                  className="form-control"
                  value={lastname}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
              </div>
              
              </div>

              <div className="form-row">
              <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="mobilenumber">Enter Mobile Number</label>
                <input
                  type="text"
                  name="mobilenumber"
                  className="form-control"
                  value={mobilenumber}
                  onChange={(e) => setMobileNumber(e.target.value)}
                />
              </div>
              </div>

              <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="password">Enter Password</label>
                <input
                  type="password"
                  name="password"
                  className="form-control"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              </div>

              </div>


              <div className="form-group">
              <label htmlFor="role">Assign Role to User</label>
                <div className="mt-3">
                  <div className="custom-control custom-radio custom-control-inline mb-2">
                    <input
                      type="radio"
                      id="admin"
                      name="role"
                      className="custom-control-input "
                      value="1"
                      checked={role === 1}
                      onChange={() => setRole(1)}
                    />
                    <label className="custom-control-label" htmlFor="admin"> ADMINISTRATOR </label>
                  </div>
                
                  <div className="custom-control custom-radio custom-control-inline">
                    <input
                      type="radio"
                      id="car"
                      name="role"
                      className="custom-control-input"
                      value="2"
                      checked={role === 2}
                      onChange={() => setRole(2)}
                    />
                    <label className="custom-control-label" htmlFor="car"> CAR OWNER</label>
                  </div>
               
                  <div className="custom-control custom-radio custom-control-inline">
                    <input
                      type="radio"
                      id="card"
                      name="role"
                      className="custom-control-input"
                      value="3"
                      checked={role === 3}
                      onChange={() => setRole(3)}
                    />
                    <label className="custom-control-label" htmlFor="card"> CARD OWNER</label>
                  </div>
                
                  <div className="custom-control custom-radio custom-control-inline">
                    <input
                      type="radio"
                      id="no"
                      name="role"
                      className="custom-control-input"
                      value="0"
                      checked={role === 0}
                      onChange={() => setRole(0)}
                    />
                    <label className="custom-control-label" htmlFor="no"> NO ROLE</label>
                  </div>
                </div>
              </div>

              <div className="form-group">
              <label htmlFor="isactive">Activate / Deactive User</label>
                <div className="mt-3">
                  <div className="custom-control custom-radio custom-control-inline mb-2">
                    <input
                      type="radio"
                      id="active"
                      name="isactive"
                      className="custom-control-input "
                      value="true"
                      checked={role === 1}
                      onChange={() => setActive('true')}
                    />
                    <label className="custom-control-label" htmlFor="active"> ACTIVATE USER </label>
                  </div>
                
                  <div className="custom-control custom-radio custom-control-inline">
                    <input
                      type="radio"
                      id="deactive"
                      name="isactive"
                      className="custom-control-input"
                      value="false"
                      checked={role === 0}
                      onChange={() => setActive('false')}
                    />
                    <label className="custom-control-label" htmlFor="deactive"> DEACTIVATE USER</label>
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <button
                  type="submit"
                  className="btn btn-info btn-block"
                  onClick={handleNewUser}
                  disabled={loading}
                >
                  {loading ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span className="sr-only">Loading...</span>
                    </>
                  ) : (
                    "Save Data"
                  )}
                </button>
                </div>                      
              </form>
              </div>
            </div>
        </div>
      </div>
    </div>
  </section>
</main>

  );
};

export default NewUser;