import React,  { useState, useEffect }from "react";
import { useNavigate, Link } from "react-router-dom";
import Swal from "sweetalert2";
import HttpApi from '../api/HttpApi';
import { useDashboardData } from "../hooks/global";
import DashboardLayout from "../components/DashboardLayout";


const Profile = () => {
  const navigate = useNavigate();
  const { postMediaRequest, postRequest, getRequest, token, logout } = HttpApi();
  const [investor, setInvestor] = useState([]);

  useEffect(() => {
    const fetchInvestor = async () => {
      try {
        const response = await getRequest('dashboard');
        setInvestor(response.data.data.investor);
      } catch (error) {
        console.error(error);
      }
    };

    fetchInvestor();
 }, []);


  const logOut = () => {
    if(token != undefined){
        logout();
    }
    getRequest('logout')
    .then((response)=>{
      navigate('/');
    });
  }

  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleFocus = (field) => {
    setErrors((prevErrors) => ({ ...prevErrors, [field]: undefined }));
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    setLoading(true);

    const password_data = {
      password: password,
      confirm_password: confirm_password,
    };
    
    try {
      const response = await postRequest("updatepassword", password_data);

      if (response.data.status === "success") {
        setErrors({});
        showNotification("success", response.data.message.success[0]);
        logOut();
      } else if (response.data.status === "error") {
        setErrors(response.data.message.error);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const [previewUrl, setPreviewUrl] = useState("");

  useEffect(() => {
    setPreviewUrl(investor.investor_photo);
  }, [investor.investor_photo]);

  
  const handlePhotoChange = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];

    if (!file) {
      // Handle case where no file is selected
      return;
    }

    const formData = new FormData();
    formData.append('photo', file);

    try {
      const response = await postMediaRequest("updatephoto", formData);
      if (response.data.status === "success") {
        setPreviewUrl(response.data.photo_url);
        //const imageUrl = URL.createObjectURL(file);
        showNotification("success", response.data.message.success[0]);
      } else if (response.data.status === "error") {
        showNotification("error", "Failed to upload the photo");
      }
    } catch (error) {
      console.log(error);
    }
  };
  

const showNotification = (icon, title) => {
  Swal.fire({
    icon: icon,
    title: title,
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 4000,
    timerProgressBar: true,
    customClass: {
      popup: "colored-toast",
    },
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
};

   return (
    <DashboardLayout>
      <div className="content-body">
         <div className="container-fluid">
  <div className="row">
    <div className="col-md-8 mx-auto">
      <div className="page-heading">
        <div className="page-heading__title">
          <h3>
            <a
              href="javascript:void(0);"
              onclick="goBack()"
              className="btn btn-sm btn-dark mr-2"
            >
              {" "}
              <i className="fa fas fa-reply" /> Back
            </a>
            fgfg
          </h3>
        </div>
      </div> 
    </div>
  </div>
  <div className="row">
    <div className="col-md-8 mx-auto">
      <div className="row">
        <div className="col-md-11 mx-auto">
          <div className="card">
            <div className="card-body">
              <h4 className="mb-2">Profile Information</h4>
              <form action="#" method="POST" id="profile_form">
                <div className="form-row">
                  <div className="col-md-6 mb-3">
                    <label htmlFor="name">Names</label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      className="form-control"
                      defaultValue=""
                    />
                    <span className="invalid-feedback" />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="form-control"
                      defaultValue=""
                    />
                    <span className="invalid-feedback" />
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-md-6 mb-3">
                    <label htmlFor="telephone">Telephone</label>
                    <input
                      type="text"
                      name="telephone"
                      id="telephone"
                      className="form-control"
                      defaultValue=""
                    />
                    <span className="invalid-feedback" />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="address">Current Address</label>
                    <input
                      type="text"
                      name="address"
                      id="address"
                      className="form-control"
                      defaultValue=""
                    />
                    <span className="invalid-feedback" />
                  </div>
                </div>
                <div className="form-group mt-4">
                  <button
                    type="submit"
                    className="btn btn-block btn-info"
                    id="btn_profile"
                  >
                    Update Information
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
    </div>

    
  </DashboardLayout>
    );
};

export default Profile;
