import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import DashboardLayout from "../components/DashboardLayout";
import Api from '../api/Api';
 
const Terminals = () => {
   const navigate = useNavigate();
   const [activeTab, setActiveTab] = useState('terminals');

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };
  const goBack = () => {
   // Implement your goBack function here
 };

   const { postRequest, getRequest} = Api();

   const [terminals, setTerminal] = useState([]);
   const [cars, setCar] = useState([]);
   const [selectedCar, setSelectedCar] = useState('');
   
   useEffect(() => {
      const fetchTerminals = async () => {
        try {
            const response = await getRequest('terminal');
            setTerminal(response.data);
          } catch (error) {
            console.error(error);
        }
      };
      const fetchCars = async () => {
         try {
             const response = await getRequest('car');
             setCar(response.data);
           } catch (error) {
             console.error(error);
         }
       };
       fetchCars();
       fetchTerminals();
   }, []);

 const [loading, setLoading] = useState(false);
 const [terminalid, setTerminalId] = useState("");

 const handleTerminalCar = async (e) => {
   e.preventDefault();
   setLoading(true);

   const newTerminalData = {
      terminal_id: terminalid,
      is_active: 'true',
      car_id: selectedCar
   };
 
   try {
     const response = await postRequest("terminal/", newTerminalData);
     if (response.status === 201) {
       showNotification("success", 'New Terminal created successfully!');
       setTerminalId("");
       setSelectedCar("")
       window.location.reload();
     }
   } catch (error) {
     if(terminalid == '' || selectedCar == ''){
       showNotification("error", "Please enter all the fields");
     }else {
       if (error.response && error.response.status === 422) {
         const errorDetail = error.response.data.detail;
         errorDetail.forEach(error => {
           const message = error.msg;
           showNotification("error", message);
         });
       } else if (error.response && error.response.status === 400) {
         showNotification("error", error.response.data.detail);
       } else {
         showNotification("error", "Server Error");
       }
     }
   } finally {
     setLoading(false);
   }
 };

const showNotification = (icon, title) => {
 Swal.fire({
   icon: icon,
   title: title,
   toast: true,
   position: "top-end",
   showConfirmButton: false,
   timer: 4000,
   timerProgressBar: true,
   customClass: {
     popup: "colored-toast",
   },
   didOpen: (toast) => {
     toast.addEventListener("mouseenter", Swal.stopTimer);
     toast.addEventListener("mouseleave", Swal.resumeTimer);
   },
 });
};


   return ( 
      <DashboardLayout>
        <div className="content-body">
            <div className="container-fluid">
               <div className="row">
                  <div className="col-md-8 mx-auto">
                     <div className="page-heading">
                        <div className="page-heading__title">
                           <h3>
                           {/* <a href="#" onClick={goBack} className="btn btn-sm btn-info mr-2">
                              {' '}
                              <i className="fa fas fa-reply" /> Back
                           </a> */}
                           Terminals
                           </h3>
                        </div>
                        <div className="page-heading__title">
                           <ul className="nav nav-tabs">
                           <li className="nav-item">
                              <a
                                 className={`nav-link ${activeTab === 'terminals' ? 'active' : ''}`}
                                 href="#terminals"
                                 onClick={() => handleTabClick('terminals')}
                              >
                                 <i className="fas fa-chart-line" /> Terminals
                              </a>
                           </li>
                           <li className="nav-item">
                              <a
                                 className={`nav-link ${activeTab === 'newterminal' ? 'active' : ''}`}
                                 href="#newterminal"
                                 onClick={() => handleTabClick('newterminal')}
                              >
                                 <i className="far fa-user" /> New Terminal
                              </a>
                           </li>
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
         
               <div className="row mb-3">
                  <div className="col-md-8 mx-auto">
                     <div className="page-title-content">
                        <div className="tab-content">
                           <div className={`tab-pane show ${activeTab === 'terminals' ? 'active' : ''}`} id="terminals">
                           
                           <div className="card">
                              <div className="card-body">
                                 <h4 className="card-title">Registered Terminals</h4>
                                 <div className="table-responsive">
                                    <table className="table mb-0">
                                    <thead>
                                       <tr>
                                          <th>#</th>
                                          <th>Terminal ID.</th>
                                          <th>Car</th>
                                          <th>Status</th>
                                          <th>Action</th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                    {terminals.map((terminal, index) => (
                                       <tr key={terminal.terminal_id}>
                                          <th scope="row">{index + 1}</th>
                                          <td>{terminal.terminal_id}</td>
                                          <td>{terminal.car_id}</td>
                                          <td>{terminal.is_active}</td>
                                          <td>
                                          <Link to={`/terminal/update/${terminal.terminal_id}`}>
                                             <button className="btn btn-sm btn-dark mr-2"><i className="far fa-edit"/></button>
                                          </Link>
                                          <Link to="">
                                             <button className="btn btn-sm btn-danger"><i className="fa fa-trash"/></button>
                                          </Link>
                                          </td>
                                       </tr>
                                    ))}
                                    </tbody>
                                    </table>
                                 </div>
                              </div>
                           </div>
                           

                           </div>
                           <div className={`tab-pane ${activeTab === 'newterminal' ? 'active' : ''}`} id="newterminal">

                           <div className="row justify-content-md-center">
                              <div className="col-md-10">
                        <div className="card">
                              <div className="card-body">
                              <p className="mb-4">New Terminal Information</p>
                              <form onSubmit={handleTerminalCar}>
                              <div className="form-row">
                              <div className="col-md-6">
                              <div className="form-group">
                                 <label htmlFor="terminalid">Enter Terminal</label>
                                 <input
                                    type="text"
                                    name="terminalid"
                                    className="form-control"
                                    value={terminalid}
                                    onChange={(e) => setTerminalId(e.target.value)}
                                 />
                              </div>
                              </div>
                              <div className="col-md-6">
                                 <div className="form-group">
                                    <label htmlFor="carid">Select a Car</label>
                                    <select
                                       className="form-control"
                                       name="carid"
                                       id="carid"
                                       value={selectedCar}
                                       onChange={(e) => setSelectedCar(e.target.value)}
                                    >
                                       <option value="">Select Car</option>
                                       {cars.map(car => (
                                          <option key={car.reg_number} value={car.reg_number}>{car.reg_number}</option>
                                       ))}
                                    </select>
                                    <span className="invalid-feedback" />
                                 </div>
                              </div>

                              </div>
                              
                              <div className="mt-4">
                                 <button
                                    type="submit"
                                    className="btn btn-info btn-block"
                                    onClick={handleTerminalCar}
                                    disabled={loading}
                                 >
                                    {loading ? (
                                    <>
                                       <span
                                          className="spinner-border spinner-border-sm"
                                          role="status"
                                          aria-hidden="true"
                                       ></span>
                                       <span className="sr-only">Loading...</span>
                                    </>
                                    ) : (
                                    "Save Data"
                                    )}
                                 </button>
                                 </div>                      
                              </form>
                              </div>
                              </div>
                        </div>
                        </div>
                           
                           

                           </div>
                        </div>
                     </div>
                  </div>
               </div>

            </div>
         </div>
      </DashboardLayout>
    );
};

export default Terminals;