import { Link } from "react-router-dom";
import { useUserData } from "../hooks/global"; 

const DashboardSidebar = () => {
  const user = useUserData();

  return (
    <div className="sidebar">
      <div className="menu" id="d_menu">
        <ul>

        
          <li>
            <Link to="/dashboard">
              <a className="dashboardIcon">
                <span>
                  <i className="bi bi-house"></i>
                  Home
                </span>
              </a>
            </Link>
          </li>
       
        {user.role === 1 && (
          <li>
            <Link to="/carowners">
              <a>
                <span>
                  <i className="bi bi-globe2"></i>
                  Car Owners
                </span>
              </a>
            </Link>
          </li>
        )}
        {user.role === 1 && (
          <li>
            <Link to="/cars">
              <a>
                <span>
                  <i className="bi bi-wallet2"></i>
                  Cars
                </span>
              </a>
            </Link> 
          </li>
        )}
        
        {user.role === 1 && (
          <li>
            <Link to="/terminals">
              <a>
                <span>
                  <i className="bi bi-wallet2"></i>
                  Terminals
                </span>
              </a>
            </Link> 
          </li>
        )}

          <li>
            <Link to="/cards">
              <a>
                <span>
                  <i className="bi bi-wallet2"></i>
                  Cards
                </span>
              </a>
            </Link> 
          </li>

          <li>
            <Link to="/cardowners">
              <a>
                <span>
                  <i className="bi bi-wallet2"></i>
                  Card Owners
                </span>
              </a>
            </Link> 
          </li>

          <li>
            <Link to="/profile">
              <a id="settings__mainMenu">
                <span>
                  <i className="bi bi-gear"></i>
                  Profile
                </span>
              </a>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default DashboardSidebar;
