import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import DashboardLayout from "../components/DashboardLayout";
import Swal from "sweetalert2";
import Api from '../api/Api';

const TerminalUpdate = () => {
  const navigate = useNavigate();
  const {getRequest, patchRequest} = Api();
  const { terminal_id } = useParams()

  const [loading, setLoading] = useState(false);
  const [terminalid, setTerminalId] = useState("");
  const [selectedCar, setSelectedCar] = useState('');
  const [terminal, setTerminal] = useState("");
  const [cars, setCar] = useState([]);

   useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await getRequest('terminal/' + terminal_id);
            setTerminal(response.data);
            setTerminalId(response.data.terminal_id);
            setSelectedCar(response.data.car_id);
        } catch (error) {
          console.error(error);
        }
      };
      const fetchCars = async () => {
         try {
             const response = await getRequest('car');
             setCar(response.data);
           } catch (error) {
             console.error(error);
         }
       };
       fetchCars();
      fetchData();
   }, []);

  const handleUpdateTerminal = async (e) => {
   e.preventDefault();
   setLoading(true);

   try { 
     const updateTerminal = {
      terminal_id: terminalid,
      is_active: 'true',
      car_id: selectedCar
     };
 
     const response2 = await patchRequest('terminal/' + terminal_id, updateTerminal);
     if (response2.status === 201) {
       showNotification("success", 'Data Updated successfully!');
       navigate('/cars');
     }
   } catch (error) {
      if(terminalid == '' || selectedCar == ''){
       showNotification("error", "Please enter all the fields");
     } else {
       if (error.response && error.response.status === 422) {
         const errorDetail = error.response.data.detail;
         errorDetail.forEach(error => {
           const message = error.msg;
           showNotification("error", message);
         });
       } else if (error.response && error.response.status === 400) {
         showNotification("error", error.response.data.detail);
       } else {
         showNotification("error", "Server Error");
       }
     }
   } finally {
     setLoading(false);
   }
 };

const showNotification = (icon, title) => {
  Swal.fire({
    icon: icon,
    title: title,
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 4000,
    timerProgressBar: true,
    customClass: {
      popup: "colored-toast",
    },
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
};
 
  return (
   <DashboardLayout>
    <div className="content-body">
         <div className="container-fluid">
         <div className="row">
                  <div className="col-md-8 mx-auto">
                     <div className="page-heading">
                        <div className="page-heading__title">
                           <h3>
                           {/* <a href="#" onClick={goBack} className="btn btn-sm btn-info mr-2">
                              <i className="fa fas fa-reply" /> Back
                           </a> */}
                           Update Terminal - {terminal.terminal_id}
                           </h3>
                        </div>
                        
                     </div>
                  </div>
               </div>


      <div className="row mb-3">
      <div className="col-md-8 mx-auto">
      <div className="card">
                              <div className="card-body">
                              <p className="mb-4">Update Terminal Information</p>
                              <form onSubmit={handleUpdateTerminal}>
                              <div className="form-row">
                              <div className="col-md-6">
                              <div className="form-group">
                                 <label htmlFor="terminalid">Enter Terminal</label>
                                 <input
                                    type="text"
                                    name="terminalid"
                                    className="form-control"
                                    value={terminalid}
                                    onChange={(e) => setTerminal(e.target.value)}
                                 />
                              </div>
                              </div>
                              <div className="col-md-6">
    <div className="form-group">
        <label htmlFor="carid">Car</label>
        <select
            className="form-control"
            name="carid"
            id="carid"
            value={selectedCar}
            onChange={(e) => setSelectedCar(e.target.value)}
        >
            {cars.map(car => (
                <option
                    key={car.reg_number}
                    value={car.reg_number}
                    selected={car.reg_number === selectedCar}
                >
                    {car.reg_number}
                </option>
            ))}
        </select>
        <span className="invalid-feedback" />
    </div>
</div>


                              </div>
                              
                              <div className="mt-4">
                                 <button
                                    type="submit"
                                    className="btn btn-info btn-block"
                                    onClick={handleUpdateTerminal}
                                    disabled={loading}
                                 >
                                    {loading ? (
                                    <>
                                       <span
                                          className="spinner-border spinner-border-sm"
                                          role="status"
                                          aria-hidden="true"
                                       ></span>
                                       <span className="sr-only">Loading...</span>
                                    </>
                                    ) : (
                                    "Update Data"
                                    )}
                                 </button>
                                 </div>                      
                              </form>
                              </div>
                              </div>
        </div>
      </div>
    </div>
    </div>
</DashboardLayout>

  );
};

export default TerminalUpdate;